import qs from 'qs'
import urlJoin from 'url-join'

import { tokenStore } from './tokenStore.js'
import { TOKEN_KEY, LOCATION_KEY } from './constants.js'

const FIVE_MINUTES = 1000 * 60 * 5
const tokenBase = {
  get isExpired() {
    return this.expiresAt * 1000 < Date.now()
  },
  get shouldRefresh() {
    return this.expiresAt * 1000 < Date.now() + FIVE_MINUTES
  }
}

export const makeToken = token => Object.assign(Object.create(tokenBase), token)

export function getTokenFromStore() {
  return tokenStore
    .getItem(TOKEN_KEY)
    .then(result => {
      if (result === null || result === undefined) {
        return null
      }

      return makeToken(result)
    })
    .then(token => tokenIfNotExpired(token))
}

export function hasPreviousLogin() {
  return tokenStore.getItem(TOKEN_KEY).then(result => !!result)
}

export function getTokenFromRedirectOrStore() {
  return getTokenFromStore().then(token => {
    return token || getTokenFromRedirect()
  })
}

export function getTokenFromRedirect() {
  return new Promise((resolve, reject) => {
    try {
      let token = parseRedirectToken(window.location.hash)

      if (!token) {
        return resolve(null)
      }

      window.location.hash = '/'

      token = makeToken(unpackEncodedToken(token))

      tokenStore
        .setItem(TOKEN_KEY, token)
        .then(() => tokenIfNotExpired(token))
        .then(() => resolve(token))
        .catch(error => reject(error))
    } catch (error) {
      reject(error)
    }
  })
}

function tokenIfNotExpired(token) {
  if (!token) {
    return Promise.resolve(null)
  }

  if (token.isExpired) {
    return logout().then(() => undefined)
  }

  return Promise.resolve(token)
}

function parseRedirectToken(value) {
  if (!value || !value.length) {
    return null
  }

  if (value[0] === '#') {
    value = value.substring(1)
  }

  let token = qs.parse(value)

  if (token.error) {
    window.location.hash = '/'
    throw new Error(`Error logging in: ${token.error_description || 'Unknown Error'}`)
  }

  return token.access_token ? token : null
}

const parseJwtPayload = payload => JSON.parse(window.atob(payload))

function unpackEncodedToken(token) {
  if (!token || !token.access_token) return null

  let accessValues = token.access_token.split('.')
  let openIdValues = token.id_token.split('.')
  let accessClaims = parseJwtPayload(accessValues[1])
  let openIdClaims = parseJwtPayload(openIdValues[1])

  return {
    accessToken: token.access_token,
    openIdToken: token.id_token,
    expiresIn: token.expires_in,
    expiresAt: accessClaims.exp,
    username: accessClaims.sub,
    email: openIdClaims.email,
    name: openIdClaims.name,
    groups: accessClaims.groups || [],
    claims: accessClaims, // in case extra claims are needed by specific applications
    scope: accessClaims.scp
  }
}

export const login = config => {
  let route = {
    pathname: window.location.pathname,
    query: window.location.search ? qs.parse(window.location.search.substring(1)) : null
  }

  return tokenStore.setItem(LOCATION_KEY, route).then(() => {
    window.location.assign(getLoginUrl({ config }))
  })
}

export function getLoginUrl({ config, skipPrompt, state } = {}) {
  state = state || '1'

  if (config.scope && !config.scope.includes('openid')) {
    throw new Error('Error logging in: the list of scopes must include openid')
  }

  return urlJoin(
    config.authorization,
    '?' +
      qs.stringify({
        response_type: 'id_token token',
        client_id: config.client_id,
        scope: config.scope || 'profile openid email idpType',
        redirect_uri: config.redirect_uri,
        nonce: '1',
        state,
        prompt: skipPrompt ? 'none' : undefined
      })
  )
}

export const getPreviousLocation = () => {
  return tokenStore.getItem(LOCATION_KEY).then(route => {
    return route
      ? tokenStore
          .removeItem(LOCATION_KEY)
          .then(() => route)
          .catch(error => {
            console.log(`Error clearing token store: ${error.message}`)

            return route
          })
      : null
  })
}

export function logout() {
  return tokenStore.removeItem(TOKEN_KEY).catch(error => {
    console.error(`Error clearing token store: ${error.message}`)
  })
}
