import {
  loginUser,
  innerLoginUser,
  logoutUser,
  innerLogoutUser,
  initializeUserAuth,
  innerInitializeUserAuth
} from './actions.js'
import { initStore } from './tokenStore.js'

const createAuthMiddleware = ({ config, authEvents = {} } = {}) => {
  validateConfig(config)
  initStore(config.store)
  return ({ dispatch, getState }) => next => action => {
    switch (action.type) {
      case loginUser.type:
        innerLoginUser(config, authEvents)(dispatch, getState)
        break
      case logoutUser.type:
        innerLogoutUser(authEvents)(dispatch, getState)
        break
      case initializeUserAuth.type:
        innerInitializeUserAuth(config, authEvents)(dispatch, getState)
        break
    }

    next(action)
  }
}

function validateConfig(config) {
  validate('authorization', config.authorization)
  validate('client_id', config.client_id)
  validate('redirect_uri', config.redirect_uri)
  validate('response_type', config.response_type)
  validate('scope', config.scope)
  if (config.store) {
    validate(config.store.tokenName, 'store.tokenName')
    validate(config.store.hydrateName, 'store.hydrateName')
  }
}

function validate(key, prop, type = 'string') {
  if (typeof prop !== type) {
    throw new Error(`Error validating oauth configuration: ${key} must be a(n) ${type}`)
  }
}

export default createAuthMiddleware
