(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("qs"), require("url-join"));
	else if(typeof define === 'function' && define.amd)
		define(["qs", "url-join"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("qs"), require("url-join")) : factory(root["qs"], root["url-join"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__8__) {
return 