/* eslint-disable no-use-before-define */
import { SILENT_LOGIN_IFRAME_ID } from './constants.js'
import { getTokenFromRedirect, getLoginUrl, makeToken, hasPreviousLogin } from './lib.js'

export const inIframe = () => {
  try {
    return window.self !== window.top
  } catch (error) {
    return true
  }
}

export const iframeSilentLogin = () => {
  return getTokenFromRedirect()
    .then(token => {
      let message = JSON.stringify({
        type: 'SILENT_LOGIN',
        token
      })

      window.parent.postMessage(message, window.origin)
    })
    .catch(error => {
      let message = JSON.stringify({
        type: 'SILENT_LOGIN',
        error: error.message
      })

      window.parent.postMessage(message, window.origin)
    })
}

export const trySilentLogin = config => async (dispatch, getState) => {
  let shouldAttempt = await hasPreviousLogin()

  if (!shouldAttempt) {
    return Promise.resolve(null)
  }

  let silentLogin = silentLoginListener()(dispatch, getState)

  window.addEventListener('message', silentLogin.handler, false)

  createSilentLoginIframe(config)

  return silentLogin.promise
}

const tryJsonParse = value => {
  let parsed

  try {
    parsed = JSON.parse(value)
  } catch (error) {
    return undefined
  }

  return parsed
}

const createSilentLoginIframe = config => {
  let doc = window.document

  let iframe = doc.createElement('iframe')

  iframe.id = SILENT_LOGIN_IFRAME_ID
  iframe.width = '0px'
  iframe.height = '0px'

  doc.body.appendChild(iframe)
  iframe.src = getLoginUrl({
    config,
    skipPrompt: true,
    state: 'silent'
  })
}

const cleanupSilentLoginIframe = () => {
  let doc = window.document
  let iframe = doc.getElementById(SILENT_LOGIN_IFRAME_ID)

  if (iframe) {
    doc.body.removeChild(iframe)
  }
}

const silentLoginListener = () => () => {
  let result = {}
  let hasCanceled = false
  let timeout = 5000

  result.promise = new Promise((resolve, reject) => {
    result.handler = event => {
      if (hasCanceled) {
        return
      }

      if (event.origin !== window.origin) {
        return
      }

      let data = tryJsonParse(event.data)

      if (!data || data.type !== 'SILENT_LOGIN' || !data.token) {
        return
      }

      window.removeEventListener('message', result.handler, false)

      cleanupSilentLoginIframe()

      if (data.error) {
        reject(data.error)
      } else {
        resolve(makeToken(data.token))
      }
    }

    setTimeout(() => {
      hasCanceled = true

      reject(new Error('Silent Login Timed out'))
    }, timeout)
  })

  return result
}
