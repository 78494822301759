/* eslint-disable no-use-before-define */
import deepEqual from 'deep-equal'
import { createAction, asyncAction } from '@nike/redux-action-utils'

import { RECOVERY_KEY } from './constants.js'
import { hydrateStore } from './tokenStore.js'
import { inIframe, iframeSilentLogin, trySilentLogin } from './silentLogin.js'
import { login, logout, getPreviousLocation, getTokenFromRedirectOrStore } from './lib.js'
import {
  hasAuthCheckStarted,
  selectIsSilentAuthRunning,
  selectLoginError,
  selectIsLoggedIn
} from './reducer.js'

export const initializeUserAuth = createAction('AUTH_CHECK_INITIALIZE')
export const setSilentAuthCheck = createAction('AUTH_CHECK_SILENT')
export const authCheckStart = createAction('AUTH_CHECK_START')
export const authCheckComplete = createAction('AUTH_CHECK_COMPLETE')
export const setAuthCheckError = createAction('AUTH_CHECK_ERROR')

export const loginUser = createAction('LOGIN_USER', { noPayload: true })
export const userLoginSuccess = createAction('USER_LOGIN_SUCCESS')
export const setLoginError = createAction('LOGIN_SILENT_ERROR')

export const logoutUser = createAction('LOGOUT_USER_INITIALIZE', {
  noPayload: true
})

export const setPreviousLocation = createAction('SET_PREVIOUS_LOCATION')

export const innerLoginUser = (config, auth) => (dispatch, getState) => {
  return innerLogoutUser(auth)(dispatch, getState)
    .then(() => dispatch(authCheckStart()))
    .then(() => trySilentLogin(config)(dispatch, getState))
    .catch(error => {
      console.log(error)

      return login(config)
    })
    .then(result => {
      // Silent login may return null
      return result || login(config)
    })
    .then(token => {
      return finishLogin(config, token, auth)(dispatch, getState)
    })
}

const finishLogin = (config, token, auth) => (dispatch, getState) => {
  if (!token) {
    return
  }

  watchForTokenExpiration(config, token, auth)(dispatch, getState)

  dispatch(userLoginSuccess(token))

  return getPreviousLocation()
    .then(previousLocation => dispatch(setPreviousLocation(previousLocation)))
    .then(() => auth.emit('loginSuccess', dispatch, getState))
    .catch(error => {
      dispatch(
        setLoginError(
          `An error occurred after login, unable to restore state: ${error}. You will be logged out.`
        )
      )

      auth.emit('loginError', dispatch, getState)

      return innerLogoutUser(auth)(dispatch, getState)
    })
    .then(() => {
      dispatch(authCheckComplete())

      return token
    })
}

export const innerLogoutUser = asyncAction({
  baseName: 'USER_LOGOUT',
  action: action => auth => (dispatch, getState) => {
    dispatch(action.start())

    return logout()
      .then(() => {
        auth.emit('logoutSuccess', dispatch, getState)

        dispatch(action.success())
      })
      .catch(error => {
        auth.emit('logoutError', dispatch, getState)

        dispatch(action.error(error))
      })
  }
})

export const innerInitializeUserAuth = (config, auth) => (dispatch, getState) => {
  if (inIframe()) {
    return iframeSilentLogin()
  }

  if (hasAuthCheckStarted(getState())) {
    return Promise.resolve()
  }

  dispatch(authCheckStart())

  return recoverExpiredState()(dispatch, getState)
    .then(() => getTokenFromRedirectOrStore())
    .catch(error => {
      dispatch(setLoginError(error))

      auth.emit('loginError', dispatch, getState)
    })
    .then(token => {
      if (token) {
        return finishLogin(config, token, auth)(dispatch, getState)
      }
      return innerLoginUser(config, auth)(dispatch, getState)
    })
}

const recoverExpiredState = () => (dispatch, getState) => {
  return hydrateStore
    .getItem(RECOVERY_KEY)
    .catch(error => {
      console.log(error)

      throw error
    })
    .then(recoveredState => {
      if (!recoveredState || !hasRecoverableState(recoveredState)) {
        return
      }

      return waitUntilPendingCompleted()(dispatch, getState)
        .then(() => {
          hydrateStore.removeItem(RECOVERY_KEY)
        })
        .catch(error => {
          console.error('error waiting for pending', error)
        })
    })
}

const hasRecoverableState = state => {
  let hasFormChanges = false

  if (state.form) {
    hasFormChanges = Object.keys(state.form).some(key => {
      let form = state.form[key]

      return form.anyTouched && !deepEqual(form.initial, form.values)
    })
  }

  return hasFormChanges
}

const waitUntilPendingCompleted = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let retryUntil = new Date(Date.now() + 20000)

    let watcher = setInterval(() => {
      if (retryUntil.getTime() - Date.now() < 0) {
        clearInterval(watcher)

        reject(new Error('error recovering expired login'))
      }
      if (!isAnyRequestPending(getState())) {
        clearInterval(watcher)
        resolve()
      }
    }, 20)
  })
}

const isAnyRequestPending = state => {
  return Object.keys(state.request).some(key => {
    let action = state.request[key] || {}

    return action.isPending
  })
}

const refreshSilentLogin = (config, auth) => (dispatch, getState) => {
  let state = getState()
  if (selectIsSilentAuthRunning(state) || selectLoginError(state)) {
    return Promise.resolve()
  }

  dispatch(setSilentAuthCheck(true))

  return trySilentLogin(config)(dispatch, getState)
    .then(token => {
      if (!token) {
        login(config)
      }

      dispatch(userLoginSuccess(token))

      auth.emit('refreshSuccess', dispatch, getState)

      watchForTokenExpiration(config, token, auth)(dispatch, getState)
    })
    .catch(error => {
      dispatch(setLoginError(error))

      auth.emit('refreshError', dispatch, getState)
    })
    .finally(() => dispatch(setSilentAuthCheck(false)))
}

let tokenExpirationWatcher = null
const watchForTokenExpiration = (config, token, auth) => (dispatch, getState) => {
  if (tokenExpirationWatcher) {
    clearInterval(tokenExpirationWatcher)
  }

  tokenExpirationWatcher = setInterval(() => {
    if (selectIsSilentAuthRunning(getState()) || !selectIsLoggedIn(getState())) {
      return
    }

    if (!token.isExpired && token.shouldRefresh) {
      return refreshSilentLogin(config, auth)(dispatch, getState)
    }

    if (token.isExpired) {
      return refreshSilentLogin(config, auth)(dispatch, getState).catch(error => {
        console.error('error refreshing login', error)

        return hydrateStore.setItem(RECOVERY_KEY, getState()).then(() => {
          dispatch(setLoginError('Login Expired. You must log in again'))

          innerLogoutUser(auth)(dispatch, getState)

          clearInterval(tokenExpirationWatcher)

          tokenExpirationWatcher = null
        })
      })
    }
  }, 1000)
}
