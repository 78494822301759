/* eslint-disable import/no-mutable-exports */
import store from 'localforage'

let tokenStore
let hydrateStore

export const initStore = ({ tokenName, hydrateName } = {}) => {
  if (!tokenStore) {
    initTokenStore(tokenName)
  }

  if (!hydrateStore) {
    initHydrateStore(hydrateName)
  }
}

export function initTokenStore(name = 'tokens') {
  tokenStore = store.createInstance({ name })
}

export function initHydrateStore(name = 'hydrate') {
  hydrateStore = store.createInstance({ name })
}

export { tokenStore, hydrateStore }
